import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyle } from "./components/common/styles/global";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NewsletterForm from "./components/NewsLetterForm";
import About from "./pages/AboutUs";
import Instructors from "./components/pages/compony/Instructors";
import InstructorDetails from "./components/pages/compony/InstructorDetails";
import Events from "./pages/events/Events";
import BlogDetails from "./components/pages/blog/BlogDetails";
import Councelling from "./components/pages/Services/Councelling";
import Preparation from "./components/pages/Services/Preparation";
import Selection from "./components/pages/Services/Selection";
import Application from "./components/pages/Services/Application";
import Internship from "./components/pages/Services/Internship";
import educationLoan from "./components/pages/Services/educationLoan";
import VisaProcess from "./components/pages/Services/VisaProcess";
import AliedServices from "./components/pages/Services/AliedServices";
import Scholarship from "./components/pages/Services/Scholarship";
import CourseGrid from "./components/pages/Courses/CourseGrid";
import NewsPressTabBox from "./components/pages/compony/NewsPressTabBox";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Career from "./components/pages/compony/Career";
import Counselor from "./components/pages/nextstepcounselor/Counselor";
import OnlineCounselling from "./components/pages/onlinecounselling/OnlineCounselling";
import UserApplicatons from "./components/common/userapplication/UserApplicatons";
import NotFound404 from "./components/common/errorpages/NotFound404";
import SomthingWentWrong from "./components/common/errorpages/SomthingWentWrong";
import CanadaPage from "./components/pages/StudyIn/CanadaPage";
import AusPage from "./components/pages/StudyIn/AusPage";
import UkPage from "./components/pages/StudyIn/UkPage";
import IrlPage from "./components/pages/StudyIn/IrlPage";
import UsaPage from "./components/pages/StudyIn/UsaPage";
import SwitzPage from "./components/pages/StudyIn/SwitzPage";
import SingaporePage from "./components/pages/StudyIn/SingaporePage";
import UaePage from "./components/pages/StudyIn/UaePage";
import SucessPage from "./components/pages/StudyIn/SucessPage";
import ContactUs from "./components/pages/contactus/ContactUs";

class Main extends React.Component {
  render() {
    return (
      <div className="main-wrapper">
        <Router>
          <GlobalStyle />
          <Header />
          <div className="main-content">
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Home}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                component={Login}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/registration`}
                component={Register}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/courses`}
                component={CourseGrid}
              />
              {/* <Route
                exact
                path={`${process.env.PUBLIC_URL}/instructors`}
                component={Instructors}
              /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/instructor-details`}
                component={InstructorDetails}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/events`}
                component={Events}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/blog`}
                component={SucessPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Councelling`}
                component={Councelling}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Preparation`}
                component={Preparation}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Selection`}
                component={Selection}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Scholarship`}
                component={Scholarship}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Application`}
                component={Application}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Internship`}
                component={Internship}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/education-Loan`}
                component={educationLoan}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Visa-Process`}
                component={VisaProcess}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Alied-Services`}
                component={AliedServices}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-uk`}
                component={UkPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-united-states`}
                component={UsaPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-canada`}
                component={CanadaPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-ireland`}
                component={IrlPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-australia`}
                component={AusPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-uae`}
                component={UaePage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-switzerland`}
                component={SwitzPage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/study-in-singapore`}
                component={SingaporePage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/News-Press`}
                component={NewsPressTabBox}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Forgot-Password`}
                component={ForgetPassword}
              />{" "}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/ResetPassword`}
                component={ResetPassword}
              />{" "}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Career`}
                component={Career}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/Nexstep-Counselor`}
                component={Counselor}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/online-Counselling`}
                component={OnlineCounselling}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/ContactUs`}
                component={ContactUs}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/user-applications`}
                component={UserApplicatons}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/session/404`}
                component={NotFound404}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/session/somthing-went-wrong`}
                component={SomthingWentWrong}
              />
              <Route path="*">
                <NotFound404 />
              </Route>
            </Switch>
          </div>
          <div style={{ marginBottom: "6.87em" }}>
            <NewsletterForm />
          </div>
          {/* <CountrySelectionPopUp /> */}
          {/* Margin to create space between content and footer */}
          <Footer />
        </Router>
      </div>
    );
  }
}

export default Main;
