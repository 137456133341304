import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../components/styles/loginPage/account";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import { api } from "../API/API";
import Toast from "../components/common/Toast";

function ResetPassword() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, setSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("success");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setToastMessage("New password and confirm password do not match");
      setSeverity("error");
      setOpen(true);
      return;
    }

    if (!isValidEmail(email)) {
      setToastMessage("Invalid email address");
      setSeverity("error");
      setOpen(true);
      return;
    }

    try {
      const response = await api.post("reset-password", {
        body: {
          current_password: currentPassword,
          new_password: newPassword,
        },
      });

      if (response.success) {
        setToastMessage("Password changed successfully");
        setSeverity("success");
        setOpen(true);
        history.push("/login");
      } else {
        setToastMessage(response.message || "Password reset failed");
        setSeverity("error");
        setOpen(true);
      }
    } catch (error) {
      setToastMessage("An error occurred while resetting the password");
      setSeverity("error");
      setOpen(true);
    }
  };

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <Styles>
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Reset Password" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Reset Password</h3>
                  </div>
                  <form
                    id="form_reset"
                    className="form"
                    onSubmit={handleFormSubmit}
                  >
                    <p className="form-control">
                      <label htmlFor="login_user">User Email</label>
                      <input
                        type="text"
                        placeholder="UserEmail"
                        id="login_user"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="current_password">Current Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="current_password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="new_password">New Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="confirm_password">Confirm Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="confirm_password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <button type="submit">Reset Password</button>
                    <button
                      type="button"
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </button>
                    <div className="save-forget-password d-flex justify-content-between">
                      <div className="save-password">
                        <label htmlFor="save_password">
                          <input
                            type="checkbox"
                            id="save_password"
                            className="check-box"
                          />
                          Save Password
                        </label>
                      </div>
                      <div className="not_account-btn text-center">
                        <p>
                          Haven't Any Account Yet?{" "}
                          <Link to={process.env.PUBLIC_URL + "/registration"}>
                            Click Here
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="social-login text-center">
                      <p>Login With Social</p>
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-google"></i> Google
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-facebook-f"></i> Facebook
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-twitter"></i> Twitter
                          </a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Toast
        open={open}
        onClose={() => setOpen(false)}
        message={toastMessage}
        severity={severity}
      />
    </Styles>
  );
}

export default ResetPassword;
