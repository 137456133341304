import React, { useState } from "react";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Datas from "../StudyIn/data/success.json";
import { colors } from "../../common/element/elements";

function SucessPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const itemsPerRow = 3;
  const totalItems = Datas.studentinfo.length;

  const currentItems = showAll
    ? Datas.studentinfo
    : Datas.studentinfo.slice(currentIndex, currentIndex + itemsPerRow);

  const handleNext = () => {
    if (currentIndex + itemsPerRow < totalItems) {
      setCurrentIndex(currentIndex + itemsPerRow);
    }
  };

  const handleBack = () => {
    if (currentIndex - itemsPerRow >= 0) {
      setCurrentIndex(currentIndex - itemsPerRow);
    }
  };

  const handleViewMore = () => {
    setShowAll(true);
  };

  const handleViewLess = () => {
    setShowAll(false);
    setCurrentIndex(0);
  };

  return (
    <div className="main-wrapper course-page">
      <BreadcrumbBox title="Sutdent Success Stories" />
      <section className="course-grid-area">
        <Container>
          <Box
            sx={{
              marginBottom: "6em",
              position: "relative",
              marginTop: "4em",
            }}
          >
            <div style={{ textAlign: "center", padding: 2 }}>
              <h2
                style={{
                  color: "rgb(22, 55, 90)",
                  display: "inline-block",
                  margin: 0,
                  fontWeight: 600,
                }}
              >
                Stories
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <div
                  style={{
                    width: "5%",
                    height: "2px",
                    backgroundColor: "#ffab40",
                    marginRight: "2%",
                  }}
                />
                {/* <div
                  style={{
                    width: "5%",
                    height: "2px",
                    backgroundColor: "#ffab40",
                  }}
                /> */}
              </div>
            </div>

            <div className="about" style={{ textAlign: "center" }}>
              <Typography
                sx={{
                  my: "1.5em",
                  fontSize: "1.35em",
                  color: "rgb(22, 55, 90)",
                  paddingX: "3em",
                  display: "inline-block",
                }}
              >
                98% of our students give us positive feedback, which is the real
                inspiration behind the Nxt Step Overseas success.Here are some
                of
                <span style={{ display: "block", textAlign: "center" }}>
                  our students and working professionals' testimonials submitted
                  personally.
                </span>
              </Typography>
            </div>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                mt: "2em",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Grid
                container
                spacing={1}
                className="card-content"
                justifyContent="center"
              >
                {currentItems.map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    display="flex"
                    justifyContent="center"
                    marginTop={3}
                  >
                    <Card
                      sx={{
                        width: "350px",
                        height: "100%",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.01)",
                          boxShadow: "0 4px 20px rgba(0,0,0,1)",
                        },
                      }}
                    >
                      <Box>
                        <div>
                          <img
                            src={data.image}
                            alt={"#"}
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <Box sx={{ padding: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: 2,
                              mb: 1,
                            }}
                          >
                            <img
                              src={data.countryflgs}
                              alt="flag"
                              style={{
                                width: "44px",
                                height: "44px",
                                padding: "3px",
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                              }}
                            />
                            <Typography
                              style={{
                                marginLeft: "10px",
                                fontWeight: 480,
                                fontSize: "1.15em",
                              }}
                            >
                              {data.location}
                            </Typography>
                          </Box>
                          <Box sx={{ ml: 4, mb: 1.5 }}>
                            <Typography
                              style={{
                                fontSize: "1.4em",
                                fontWeight: "bold",
                                color: "rgb(22, 55, 90)",
                              }}
                            >
                              {data.name}
                            </Typography>
                          </Box>
                          <Box sx={{ ml: 4, mb: 4 }}>
                            <Typography style={{ fontSize: "1.18em" }}>
                              {data.university}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {!showAll && isHovered && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      left: -30,
                      top: "50%",
                      transform: "translateY(-50%)",
                      bgcolor: "white",
                      borderRadius: "50%",
                      padding: "0.5em",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      visibility: currentIndex === 0 ? "hidden" : "visible",
                    }}
                  >
                    <IconButton
                      onClick={handleBack}
                      sx={{
                        color: "#333",
                        "&:hover": {
                          color: "#007bff",
                        },
                      }}
                    >
                      <ArrowBackIcon fontSize="large" />
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      right: -30,
                      top: "50%",
                      transform: "translateY(-50%)",
                      bgcolor: "white",
                      borderRadius: "50%",
                      padding: "0.5em",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      visibility:
                        currentIndex + itemsPerRow >= totalItems
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <IconButton
                      onClick={handleNext}
                      sx={{
                        color: "#333",
                        "&:hover": {
                          color: "#007bff",
                        },
                      }}
                    >
                      <ArrowForwardIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>

            {!showAll ? (
              <Box sx={{ textAlign: "center", marginTop: "7em" }}>
                <Button
                  variant="contained"
                  onClick={handleViewMore}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 500,
                    padding: "0.8em 2.5em",
                    backgroundColor: colors.H_bg1,
                    fontSize: "13px",
                    transition: "background 0.4s ease",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)",
                    },
                  }}
                >
                  View More Stories
                </Button>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "7em" }}>
                <Button
                  variant="contained"
                  onClick={handleViewLess}
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 500,
                    padding: "0.8em 2.5em",
                    backgroundColor: colors.H_bg1,
                    fontSize: "13px",
                    transition: "background 0.4s ease",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg,  rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)",
                    },
                  }}
                >
                  View Less Stories
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </section>
    </div>
  );
}

export default SucessPage;
