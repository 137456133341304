import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  styled,
  Box,
} from "@mui/material";

interface MainDialogProps {
  onClose: () => void;
  onOutsideClick: () => void;
  dialogTitle?: string;
  dialogDescription?: string;
  children?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  open: boolean;
  entityDocuments: Array<{ documentTypeId: number; documentTypeName: string }>;
  activeDocumentIds: number[];
  setActiveDocumentIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const StyledDiv = styled("div")`
  .main-dilog {
    display: flex;
    flex-wrap: wrap;
  }

  .main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3em;
  }

  .button {
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
    padding: 0.4em 0.6em 0.4em 0.6em;
    border-radius: 4px;
    font-size: 0.8em;

    &.active {
      color: black;
      border: 1px solid orange;
      padding: 0.4em 0.6em 0.4em 0.6em;
      border-radius: 4px;
    }
  }
`;

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: "relative",
  padding: "16px 24px 0",

  "& .MuiTypography-root": {
    // marginBottom: theme.spacing(1),
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const MainDialog: React.FC<MainDialogProps> = ({
  open,
  onClose,
  onOutsideClick,
  dialogTitle,
  dialogDescription,
  children,
  width = "95vw",
  height = "95vh",
  minWidth = "800px",
  minHeight = "450px",
  entityDocuments,
  activeDocumentIds,
  setActiveDocumentIds,
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onOutsideClick();
        } else {
          onClose();
        }
      }}
      maxWidth={"xl"}
      PaperProps={{
        style: {
          width: width,
          height: height,
          minWidth: minWidth,
          minHeight: minHeight,
          padding: "10px",
          maxWidth: "80vw !important",
        },
      }}
    >
      <CustomDialogTitle>
        <StyledDiv>
          <Box>
            <div>
              <Typography variant="body1" sx={{ marginBottom: "0.6em" }}>
                Please select the Intake before submit the form
              </Typography>
            </div>
            <div className="main-dilog">
              {entityDocuments.map((doc, index) => (
                <div className="main-title" key={index}>
                  <div
                    className={`button ${
                      activeDocumentIds.includes(doc.documentTypeId)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setActiveDocumentIds((prevIds) => {
                        if (prevIds.includes(doc.documentTypeId)) {
                          return prevIds.filter(
                            (id) => id !== doc.documentTypeId
                          );
                        } else {
                          return [...prevIds, doc.documentTypeId];
                        }
                      });
                    }}
                  >
                    <strong>{doc.documentTypeName}</strong>
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </StyledDiv>
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          {dialogDescription}
        </Typography>
        <CloseButton onClick={onClose}>
          <i className="las la-times"></i>
        </CloseButton>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default MainDialog;
