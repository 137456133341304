import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../components/styles/loginPage/account";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import Toast from "../components/common/Toast";
import LoginParent from "./LoginParent";
import { api } from "../API/API";
// import Toast from "../components/pages/Toast";

function ForgotPassword() {
  const history = useHistory();
  const [open, SetOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [severity, SetSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("success");
  const [loading, setLoading] = useState(false);

  const handleformsubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = document.getElementById("forgot_email") as HTMLInputElement;

    const userValue = user?.value.trim();

    if (!userValue) {
      setToastMessage("Email cannot be empty");
      SetSeverity("warning");
      SetOpen(true);
      return;
    }

    if (userValue) {
      setLoading(true);
      try {
        const response = await api.post("forgot-password-email", {
          body: {
            userEmail: userValue,
          },
        });
        if (response.success) {
          setLoading(false);
          setToastMessage(
            "Password reset email sent to the registered email address."
          );
          SetOpen(true);
          SetSeverity("success");
          history.push("/login");
        } else {
          setLoading(false);
          setToastMessage("User does not exist, please register.");
          SetOpen(true);
          SetSeverity("warning");
        }
      } catch (error) {
        console.error("Error sending password reset email:", error);
      }
    }
  };

  return (
    <Styles>
      {loading && <LoginParent />}
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Forgot Password" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Forgot Password</h3>
                  </div>
                  <form
                    id="form_forgot_password"
                    className="form"
                    onSubmit={handleformsubmit}
                  >
                    <p className="form-control">
                      <label htmlFor="forgot_email">User Email</label>
                      <input
                        type="email"
                        placeholder="UserEmail"
                        id="forgot_email"
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <button type="submit" disabled={loading}>
                      Forgot Password
                    </button>
                    <button
                      type="button"
                      onClick={() => history.push("/login")}
                    >
                      Go Back
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Toast
        open={open}
        onClose={() => SetOpen(false)}
        message={toastMessage}
        severity={severity}
      />
    </Styles>
  );
}

export default ForgotPassword;
