import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../components/styles/loginPage/account";
import { BreadcrumbBox } from "../components/common/Breadcrumb";
import { api } from "../API/API";
import appStore from "../mobstore/MobStore";
import Toast from "../components/common/Toast";
import LoginParent from "./LoginParent";

function Login() {
  const [open, SetOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [severity, SetSeverity] = useState<
    "error" | "success" | "info" | "warning"
  >("success");
  const history = useHistory();

  useEffect(() => {
    const form = document.getElementById("form_login");
    const user = document.getElementById("login_user") as HTMLInputElement;
    const password = document.getElementById(
      "login_password"
    ) as HTMLInputElement;

    form?.addEventListener("submit", formSubmit);

    const handleBlur = (input: HTMLInputElement, message: string) => {
      const inputValue = input.value.trim();
      if (inputValue === "") {
        setError(input, message);
      } else if (input.id === "login_user" && !isValidEmail(inputValue)) {
        setError(input, "Invalid email address");
      } else {
        setSuccess(input);
      }
    };

    user.addEventListener("blur", () => {
      handleBlur(user, "User email can't be blank");
    });

    password.addEventListener("blur", () => {
      handleBlur(password, "Password can't be blank");
    });

    async function formSubmit(e: Event) {
      e.preventDefault();

      const userValue = user?.value.trim();
      const passwordValue = password?.value.trim();

      let isValid = true;

      if (userValue === "") {
        setError(user, "User email can't be blank");
        isValid = false;
      } else if (!userValue.includes("@")) {
        setError(user, "Invalid email address");
        isValid = false;
      } else {
        setSuccess(user);
      }

      if (passwordValue === "") {
        setError(password, "Password can't be blank");
        isValid = false;
      } else {
        setSuccess(password);
      }

      if (submitted) {
        setSubmitted(false);
      }

      if (isValid && !submitted) {
        setLoading(true);
        try {
          const response = await api.post("login", {
            body: {
              userEmail: userValue,
              userPassword: passwordValue,
            },
          });
          setLoading(false);
          if (response.success) {
            appStore.setLoginResponse(response);
            localStorage.setItem("loginData", JSON.stringify(response));

            setToastMessage("Login successful. Welcome!");
            SetOpen(true);
            setTimeout(() => {
              history.push("/");
            }, 1000);
            SetSeverity("success");
          } else {
            setToastMessage("Please Entere  your email and password.");
            SetOpen(true);
            SetSeverity("error");
          }

          setSubmitted(true);
          appStore.setLoginResponse(response);
        } catch (error) {
          console.error("Login error:", error);
        }
      }
    }

    form?.addEventListener("submit", formSubmit);

    return () => {
      form?.removeEventListener("submit", formSubmit);
    };
  }, [submitted]);

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function setError(input: any, message: string) {
    const formControl = input.parentElement;
    const errorMsg = formControl.querySelector(".login_input-msg");
    formControl.className = "form-control text-left error";
    errorMsg.innerText = message;

    input.addEventListener("input", function handleInput() {
      formControl.classList.remove("error");
      errorMsg.innerText = "";
      input.removeEventListener("input", handleInput);
    });
  }

  function setSuccess(input: any) {
    const formControl = input.parentElement;
    formControl.className = "form-control success";
  }

  return (
    <Styles>
      {loading && <LoginParent />}
      <div className="main-wrapper login-page">
        <BreadcrumbBox title="Log In" />
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Log In</h3>
                  </div>
                  <form id="form_login" className="form">
                    <p className="form-control">
                      <label htmlFor="login_user">User Email</label>
                      <input
                        type="text"
                        placeholder="UserEmail"
                        id="login_user"
                        defaultValue="editor.siftdesk@gmail.com"
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <p className="form-control">
                      <label htmlFor="login_password">Password</label>
                      <input
                        type="password"
                        placeholder="*******"
                        id="login_password"
                        defaultValue="test@123"
                      />
                      <span className="login_input-msg"></span>
                    </p>
                    <button disabled={loading}>Log In</button>
                    <div className="save-forget-password d-flex justify-content-between">
                      <div className="save-passowrd">
                        <label htmlFor="save_password">
                          <input
                            type="checkbox"
                            id="save_password"
                            className="check-box"
                          />
                          Save Password
                        </label>
                      </div>
                      <div className="forget-password">
                        <Link to={process.env.PUBLIC_URL + "/Forgot-Password"}>
                          Forget Password?
                        </Link>
                      </div>
                    </div>
                    <div className="not_account-btn text-center">
                      <p>
                        Haven't Any Account Yet?{" "}
                        <Link to={process.env.PUBLIC_URL + "/registration"}>
                          Click Here
                        </Link>
                      </p>
                    </div>
                    {/* <div className="social-login text-center">
                      <p>Login With Social</p>
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-google"></i> Google
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-facebook-f"></i> Facebook
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fab fa-twitter"></i> Twitter
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>{" "}
      <Toast
        open={open}
        onClose={() => SetOpen(false)}
        message={toastMessage}
        severity={severity}
      />
    </Styles>
  );
}

export default Login;
