import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CourseSidebar from "./components/CourseSidebar";
import { Styles } from "./styles/course.js";
import CourseItemGrid from "./components/CourseItemGrid";
import { BreadcrumbBox } from "../../common/Breadcrumb";
import CollegeFinder from "./CollegeFinder";
import { Box } from "@mui/material";
import CourseSearch from "./components/CourseSearch";

// Define the state interface

interface CourseGridState {
  courses: any[]; // Adjust 'any' to the specific type if known
  selectedCourse: any | null; // Adjust 'any' to the specific type if known
  searchValue: string;
  selectedPriceRanges: string[];
  selectedConcentration: string | null;
  selectedCategories: string[];
}

class CourseGrid extends Component<any, CourseGridState> {
  constructor(props: any) {
    super(props);

    // Initialize state
    this.state = {
      courses: [],
      selectedCourse: null,
      searchValue: "",
      selectedPriceRanges: [],
      selectedConcentration: null,
      selectedCategories: [],
    };
  }

  handleSearchChange = (value: string) => {
    this.setState({ searchValue: value });
  };

  handlePriceChange = (selectedPriceRanges: string[]) => {
    this.setState({ selectedPriceRanges });
  };

  handleConcentrationChange = (concentration: string | null) => {
    // console.log(`Selected con..: ${concentration}`);
    this.setState({ selectedConcentration: concentration });
  };

  handleSelectedCourese = (selectedCategories: string[]) => {
    this.setState({ selectedCategories });
  };

  getFilteredCourses() {
    const {
      searchValue,
      courses,
      selectedPriceRanges,
      selectedConcentration,
      selectedCategories,
    } = this.state;

    return courses

      .filter((course) =>
        selectedConcentration
          ? course.Concentration === selectedConcentration
          : true
      )

      .filter((course) =>
        course.Name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .filter((course) => {
        const tuitionFee = course.TuitionFee;

        if (
          selectedPriceRanges.length === 0 ||
          selectedPriceRanges.includes("price1")
        ) {
          return true;
        }

        if (selectedPriceRanges.includes("price2") && tuitionFee < 10000) {
          return true;
        }
        if (
          selectedPriceRanges.includes("price3") &&
          tuitionFee >= 10000 &&
          tuitionFee <= 20000
        ) {
          return true;
        }

        if (selectedPriceRanges.includes("price4") && tuitionFee > 20000) {
          return true;
        }

        return false;
      })
      .filter((course) => {
        const match =
          selectedCategories.length === 0 ||
          selectedCategories.includes(course.Concentration);
        if (!match) {
        }
        return match;
      });
  }
  getTotalCourses() {
    return this.state.courses.length;
  }

  getCoursesBelowTenThousand() {
    const { courses } = this.state;
    return courses.filter((course) => course.TuitionFee < 10000).length;
  }

  getCoursesBetweenTenAndTwentyThousand() {
    const { courses } = this.state;
    return courses.filter(
      (course) => course.TuitionFee >= 10000 && course.TuitionFee <= 20000
    ).length;
  }

  getCoursesAboveTwentyThousand() {
    const { courses } = this.state;
    return courses.filter((course) => course.TuitionFee > 20000).length;
  }

  render() {
    const filteredCourses = this.getFilteredCourses();
    const coursesBelowTenThousand = this.getCoursesBelowTenThousand();
    const coursesBetweenTenAndTwentyThousand =
      this.getCoursesBetweenTenAndTwentyThousand();
    const coursesAboveTwentyThousand = this.getCoursesAboveTwentyThousand();
    const totalCourses = this.getTotalCourses();

    const counts = {
      all: totalCourses,
      belowTenThousand: coursesBelowTenThousand,
      betweenTenAndTwentyThousand: coursesBetweenTenAndTwentyThousand,
      aboveTwentyThousand: coursesAboveTwentyThousand,
    };

    return (
      <div className="main-wrapper course-page">
        {/* Breadcrumb */}
        <BreadcrumbBox title="Courses" />

        <Styles>
          {/* Course Grid */}
          <section className="course-grid-area">
            <Container>
              <div className="course-items">
                {this.state.courses.length === 0 && (
                  <Col
                    lg="12"
                    md="8"
                    sm="7"
                    style={{
                      padding: "25px",
                    }}
                  >
                    <CollegeFinder
                      onSearchResult={(data) => {
                        this.setState({ courses: data });
                      }}
                    />
                  </Col>
                )}
              </div>
              <Row>
                {this.state.courses.length > 0 && (
                  <>
                    <Col lg="9" md="8" sm="7">
                      <div style={{ width: "100%" }}>
                        <CourseSearch
                          onSearchChange={this.handleSearchChange}
                        />
                      </div>
                      <div className="course-items">
                        <Row>
                          <CourseItemGrid Datas={filteredCourses} />
                        </Row>
                      </div>
                    </Col>
                    <Col lg="3" md="4" sm="5">
                      <CourseSidebar
                        onSearchChange={this.handleSearchChange}
                        onPriceChange={this.handlePriceChange}
                        courses={this.state.courses}
                        onConcentrationChange={this.handleConcentrationChange}
                        onChangeSelectCategory={this.handleSelectedCourese}
                        counts={counts}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </section>
        </Styles>
      </div>
    );
  }
}

export default CourseGrid;
