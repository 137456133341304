import React, { Component, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

interface CourseItemGridProps {
  Datas: any[];
}
const CourseItemGrid: React.FC<CourseItemGridProps> = ({ Datas }) => {
  const images = [
    {
      course: "assets/images/course-1.jpg",
      author: "assets/images/author.jpg",
      rating: "3.5",
      seats: "120",
      duration: "2.2 Years",
    },
    {
      course: "assets/images/course-2.jpg",
      author: "assets/images/author.jpg",
      rating: "4.1",
      seats: "170",
      duration: "1.3 Years",
    },
    {
      course: "assets/images/course-3.jpg",
      author: "assets/images/author.jpg",
      rating: "4.0",
      seats: "216",
      duration: "1.6 Years",
    },
    {
      course: "assets/images/course-4.jpg",
      author: "assets/images/author.jpg",
      rating: "3.4",
      seats: "130",
      duration: "2 Years",
    },
    {
      course: "assets/images/course-5.jpg",
      author: "assets/images/author.jpg",
      rating: "4.4",
      seats: "156",
      duration: "3.1 Years",
    },
  ];

  return (
    <Fragment>
      {Datas.map((data, i) => {
        // console.log("data==", Datas);

        const courseImageUrl = `${process.env.PUBLIC_URL}/${images[i].course}`;
        const authorImageUrl = `${process.env.PUBLIC_URL}/${images[i]?.author}`;
        const rating = `${images[i]?.rating}`;
        const seats = `${images[i]?.seats}`;
        return (
          <Col lg="6" md="12" key={i}>
            <div className="course-item">
              <Link to={process.env.PUBLIC_URL + data.courseLink}>
                <div
                  className="course-image"
                  style={{
                    backgroundImage: `url(${courseImageUrl})`,
                  }}
                >
                  <div className="author-img d-flex">
                    <div className="img">
                      <img src={authorImageUrl} alt="" />
                    </div>
                    <div className="title">
                      <p>{data.University}</p>
                      <span>{data.Concentration}</span>
                    </div>
                  </div>
                  <div className="course-price">
                    <p>${data.TuitionFee}</p>
                  </div>
                </div>
              </Link>
              <div className="course-content">
                <h6 className="heading">
                  <Link to={process.env.PUBLIC_URL + data.courseLink}>
                    {data.Name}
                  </Link>
                </h6>
                <p>Location : {data.Location}</p>
                <span>StudyLvl: {data.StudyLvl}</span>
                <p>WorkExp : {data.WorkExp}</p>
                <p>ApplicationFee : ${data.ApplicationFee}</p>
                <p>I-20 DepositFee : ${data.I20DepositFee}</p>
                <div
                  className="course-face d-flex justify-content-between"
                  style={{ marginTop: "14px" }}
                >
                  <div className="duration">
                    <p>
                      <i className="las la-clock"></i>
                      {data.Duration}
                    </p>
                  </div>
                  <div className="rating">
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <i className="las la-star"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="las la-star"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="las la-star"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="las la-star"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="las la-star-half-alt"></i>
                      </li>
                      <li className="list-inline-item"></li>
                      {rating}
                    </ul>
                  </div>
                  <div className="student">
                    <p>
                      <i className="las la-chair"></i>
                      {seats}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
      {/* Course Item */}
      {/* {Datas.map((data, i) => (
              ))} */}
    </Fragment>
  );
};

export default CourseItemGrid;
