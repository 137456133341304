import React, { Component, Fragment, useEffect, useState } from "react";
import { BreadcrumbBox } from "../Breadcrumb";
import TestimonialSlider from "../../TestimonialSlider";
import { UserApplicatonsStyles } from "./UserApplicatonsStyles";
import { Link, useHistory } from "react-router-dom";
import userData from "./user_task1.json";
import { Box, Card, TextField, Typography } from "@mui/material";
import { colors } from "../element/elements";
import styled from "styled-components";
import { api } from "../../../API/API";
import appStore from "../../../mobstore/MobStore";
import LoginParent from "../../../pages/LoginParent";

interface TeamMember {
  name: string;
  avatar_url: string;
}

interface Application {
  university: string;
  applicationLevel: string;
  concentration: string;
  fees: number;
  progress: number;
  start_date: string;
  deadline: string;
  tasks: number;
  team_members: TeamMember[];
}
interface ActionName {
  actionName: string;
  attachments: string;
  createdDate: string;
  documentStateId: number;
  documentTypeAnswersId: number;
  documentTypeId: number;
  id: number;
  tags: {
    ActScore: number;
    ApplicationFee: string;
    BachelorsProgram: string;
    Backlogs: string;
    CGPAOrPercentage: string;
    Concentration: string;
    Country: string;
    Country1: string;
    DETScore: number | null;
    Duolingo: string;
    Duration: string;
    GRE: string;
    GmatScore: number | null;
    GreScore: number;
    I20DepositFee: string;
    IELTS: string;
    Id: number;
    IeltsOverall: number;
    Location: string;
    MastersProgram: string;
    Name: string;
    PTE: string;
    PteScore: number;
    SatScore: number;
    StudyLevelId: number;
    StudyLvl: string;
    TOEFL: string;
    ToeflScore: number;
    TuitionFee: string;
    University: string;
    UniversityId: number;
    WESRequired: string;
    WorkExp: string;
  };
  taskId: number;
  taskName: string;
  taskTableId: number;
  taskTagId: number;
  taskUsers: string;
  updatedDate: string;
  userId: number;
}

const StyledButton = styled.button`
  height: 22px;
  width: auto;
  margin-top: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${colors.button_bg1};
  border: 1px solid ${colors.button_bg1};
  color: white;
  border-radius: 4px;

  &:hover {
    background-image: ${colors.B_hv1};
    border: 1px solid ${colors.button_bg1};
  }
`;

const NoStyleButton = styled.button<{ isActive?: boolean }>`
  background-color: white;
  border: none;
  color: ${({ isActive }) => (isActive ? colors.Button1 : colors.Button2)};
  cursor: pointer;
  outline: none;
  font-size: 1.1em;

  &:focus {
    outline: none; /* Ensure no outline on focus */
  }
`;

const UserApplicatons: React.FC = () => {
  const [activeLevel, setActiveLevel] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userId = appStore.loginResponse.user[0].userId;
  const [tasks, setTasks] = useState<any[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const actionMap: Map<string, ActionName[]> = new Map();

  function getUniqueActionNames(actionNames: ActionName[]) {
    actionNames.forEach((name) => {
      const level = name.actionName;
      if (level == null || level.trim() === "") {
        return;
      }
      if (!actionMap.has(level)) {
        actionMap.set(level, []);
      }
      actionMap.get(level)?.push(name);
    });
  }
  getUniqueActionNames(tasks);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userId) {
          history.push("/session/404");
        }

        const token = appStore.loginResponse.accessToken;
        if (!token) {
          throw new Error("No access token available");
        }
        setLoading(true);

        const response = await api.get(`user/tasks/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading(false);
        appStore.setTasks(response.data);
        setTasks(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
        history.push("/session/404");
      }
    };
    fetchData();
  }, [userId]);
  const formatDateAndTime = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    const datePart = dateFormatter.format(date);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const timePart = `${hours}:${formattedMinutes} ${ampm}`;

    return `${datePart} ${timePart}`;
  };

  const filteredActionNames = tasks.filter((data) => {
    const university = data.tags.University?.toLowerCase() || "";
    const concentration = data.tags.Concentration?.toLowerCase() || "";
    const actionNames = data.actionName?.toLowerCase() || "";

    const matchesLevel = !activeLevel || data.actionName === activeLevel;

    const matchesSearchQuery =
      university.includes(searchQuery.toLowerCase()) ||
      concentration.includes(searchQuery.toLowerCase()) ||
      actionNames.includes(searchQuery.toLowerCase());

    return matchesLevel && matchesSearchQuery;
  });

  const handleButtonClick = (actionName: string) => {
    setActiveLevel(actionName);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const actionCounts = Array.from(actionMap.entries()).reduce(
    (acc, [actionName, actions]) => {
      acc[actionName] = actions.length;
      return acc;
    },
    {} as Record<string, number>
  );

  const totalactionNames = tasks.length;

  return (
    <>
      <UserApplicatonsStyles>
        {/* Main Wrapper */}
        {loading && <LoginParent />}

        <div className="main-wrapper about-page">
          {/* Breadcroumb */}
          <BreadcrumbBox title="Users Applicatons" />
          <div style={{ margin: "4em" }}>
            <Box
              sx={{
                marginTop: "3em",
                width: "100%",
              }}
            >
              <div style={{ marginBottom: "12em" }}>
                <Box
                  sx={{
                    display: "flex",
                    borderRadius: 1,
                    flexDirection: { xs: "column", md: "row" },
                    width: "100%",
                    border: "1px solid #eeeeee",
                    flexWrap: "wrap",
                    alignItems: { xs: "flex-start", md: "center" },
                  }}
                >
                  <Box
                    sx={{
                      paddingX: "1em",
                      marginX: "1.2em",
                      order: { xs: 1, md: 0 },
                      alignContent: "center",
                    }}
                  >
                    <NoStyleButton
                      onClick={() => handleButtonClick("")}
                      isActive={activeLevel === ""}
                    >
                      All({totalactionNames})
                    </NoStyleButton>
                  </Box>
                  {Array.from(actionMap.keys()).map((level, index) => (
                    <Box
                      key={`${level}-${index}`}
                      sx={{
                        padding: "0.9em",
                        marginX: "1em",
                        order: { xs: 2, md: 0 },
                      }}
                    >
                      <NoStyleButton
                        onClick={() => handleButtonClick(level)}
                        isActive={activeLevel === level}
                      >
                        {level} ({actionCounts[level]})
                      </NoStyleButton>
                    </Box>
                  ))}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: { xs: "2em", md: "auto" },
                      width: "17em",
                      justifyContent: "space-between",
                      border: "1px solid #e0e0e0",
                      backgroundColor: "white",
                      borderRadius: "0.2em",
                      mr: "1em",
                      marginTop: { xs: "1em", md: "0" },
                      marginBottom: { xs: "1em", md: "0" },
                    }}
                  >
                    <input
                      onChange={handleSearchChange}
                      type="text"
                      placeholder=" Search here ..."
                      style={{
                        width: "100%",
                        height: "2.2em",
                        border: "none",
                      }}
                    />
                    <Box
                      sx={{
                        width: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      <i
                        className="fas fa-search"
                        style={{
                          display: "Flex",
                          fontSize: "1.3em",
                          color: "#999",
                          width: "1.5em",
                          justifyContent: "center",
                          cursor: "pointer",
                          marginLeft: "0.2em",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(300px, 1fr))",
                    gap: "1.8em",
                    justifyContent: "center",
                    mt: "1em",
                  }}
                >
                  {filteredActionNames.map((data) => (
                    <div key={data.id}>
                      <Card
                        style={{
                          padding: 2,
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            padding: "1em 1em 2em",
                            borderRadius: "0.3em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{ marginTop: "10px", fontWeight: "bold" }}
                          >
                            <h6 style={{ fontWeight: "600" }}>
                              {data.tags.University}
                            </h6>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            {data.actionName && data.actionName.length > 0 && (
                              <StyledButton>{data.actionName}</StyledButton>
                            )}
                          </div>
                          <div style={{ marginTop: "30px" }}>
                            <span>
                              <i className="fas fa-user-alt"></i>
                              <strong style={{ marginLeft: 4 }}>
                                Concentration
                              </strong>
                              <Link
                                to={process.env.PUBLIC_URL + "#"}
                                style={{ marginLeft: "0.6em" }}
                              >
                                {data.tags.Concentration}
                              </Link>
                            </span>
                          </div>
                          <div style={{ marginTop: "5px" }}>
                            <i className="	fas fa-money-check-alt"></i>
                            <strong style={{ marginLeft: 3 }}> Fees </strong>
                            <strong style={{ marginLeft: "0.5em" }}>
                              ${data.tags.TuitionFee}
                            </strong>
                          </div>
                          {/* <div style={{ marginTop: "20px", width: "100%" }}>
                          <p>Progress</p>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                backgroundColor: "#e0e0e0",
                                borderRadius: "4px",
                                height: "8px",
                                marginRight: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: `${data.progress}%`,
                                  backgroundColor: colors.Button1,
                                  height: "100%",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                            <span>{data.progress}%</span>
                          </Box>
                        </div> */}
                          <div style={{ marginTop: "15px" }}>
                            <strong>Started: </strong>
                            {formatDateAndTime(data.createdDate)}
                          </div>

                          <div style={{ marginTop: "5px" }}>
                            <strong>Deadline:</strong>{" "}
                            {formatDateAndTime(data.updatedDate)}
                          </div>
                          <div>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "8px",
                                marginTop: "15px",
                              }}
                            >
                              <i className="	fas fa-user-friends"></i>
                              {/* <span>
                              <i className="fas fa-poll-h"></i>
                              <strong> {data.tasks}</strong> : Task
                            </span> */}
                            </Box>
                          </div>
                        </Box>
                      </Card>
                    </div>
                  ))}
                </Box>
              </div>
            </Box>
          </div>
        </div>
        <TestimonialSlider />
      </UserApplicatonsStyles>

      {/*  */}
    </>
  );
};

export default UserApplicatons;
