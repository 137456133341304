import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Badge,
} from "@material-ui/core";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import LoadingComponent from "./components/LoadingComponent";
import SingleSelectionComponent from "../../common/radiobutton/SingleSelectionComponent";
import { RadioButtonStyle } from "../../common/radiobutton/RadioButton";
import { api } from "../../../API/API";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(5),
      border: "0px",
    },
    fieldContainer: {
      border: "0px",
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "rgb(22 55 90)",
      color: "white",
      height: "3.8em",
    },

    buttonContainer: {
      marginTop: theme.spacing(2),
      border: "0px",
      display: "flex",
      flexDirection: "row-reverse",
      padding: theme.spacing(2),
      justifyContent: "space-between",
      borderTop: "1px solid #dedede",
      fontFamily: "sans-serif-italic",
    },
    stepTitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: 1,
    },
    stepIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#ffab40",
      marginRight: theme.spacing(1),
      color: "white",
    },
  })
);
const useButtonstyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#ffab40",
      "&:hover": {
        background:
          "linear-gradient(90deg, rgb(255 229 127) 0%, rgb(255, 171, 64) 100%)",
        border: "1px solid #ffab40",
      },
    },
  })
);

const useStepLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: "1.2rem", // Adjust the font size as needed
      color: "white",
    },
  })
);

interface Detail {
  name: string;
  value: string;
  isFilter: boolean;
  dataType: string;
  selections: { [key: string]: string };
  fieldType: number;
  trigger: TriggerType | null;
}
interface TriggerType {
  id: number;
  type: string;
  value: string;
  compareId: string;
}

interface StepData {
  title: string;
  description: string;
  columnType: number;
  details: Detail[];
}

const generateRangeSelections = (start: number, end: number) => {
  const selections: { [key: string]: string } = {};
  for (let i = start; i <= end; i++) {
    selections[i.toString()] = i.toString();
  }
  return selections;
};

const stepData: StepData[] = [
  {
    title: "University Details",
    description: "Details about the University",
    columnType: 1,
    details: [
      {
        name: "What degree do you plan to study? *",
        value: "StudyLevelId",
        isFilter: true,
        dataType: "number",
        selections: {
          "1": "Primary / Elementary School",
          "2": "Secodary School",
          "3": "Bachelor's Degree / College / Diploma / Undergraduate",
          "4": "Master's Degree / Post graduate",
          "5": "English as a Second Language (ESL) ",
          "6": "PhDs / Doctorate",
        },
        fieldType: 8,
        trigger: null,
      },
      {
        name: "Where do you want to study? *",
        value: "Country",
        isFilter: false,
        dataType: "string",
        selections: {
          "11": "United States",
          "12": "United Kingdom",
          "10": "Australia",
          "9": "Canada",
        },
        fieldType: 8,
        trigger: null,
      },
      {
        name: "Which course are you planning to study? *",
        value: "Concentration",
        isFilter: true,
        dataType: "string",
        selections: {
          "Computer Science": "Computer Science",
          "Business Administration": "Business Administration",
          Engineering: "Engineering",
          Medicine: "Medicine",
        },
        fieldType: 8,
        trigger: null,
      },
    ],
  },
  {
    title: "Course Details",
    description: "Information about the Course",
    columnType: 1,
    details: [
      {
        name: "What was your undergraduate college name? *",
        value: "College",
        isFilter: true,
        dataType: "string",
        selections: {
          "1": "one college",
          "2": "Two college",
          "3": "Four college",
        },
        fieldType: 1,
        trigger: null,
      },
      {
        name: "Which course did you major in? * ",
        value: "Course",
        isFilter: true,
        dataType: "string",
        selections: { "1": "civil", "2": "mech", "3": "Computer science" },
        fieldType: 1,
        trigger: null,
      },
      {
        name: "Year of Completion",
        value: "Duration",
        isFilter: true,
        dataType: "string",
        selections: { "1": "One Year", "2": "Two Years", "3": "Four Years" },
        fieldType: 1,
        trigger: null,
      },
      {
        name: "GPA or Percentage Obtained",
        value: "Locations",
        isFilter: true,
        dataType: "string",
        selections: { "1": "Aus", "2": "Newz", "3": "Ind" },
        fieldType: 1,
        trigger: null,
      },
      {
        name: "Intermediate English Marks 1st Year",
        value: "Marks1stYear",
        isFilter: true,
        dataType: "number",
        selections: {},
        fieldType: 1,
        trigger: {
          id: 1,
          compareId: "Country",
          type: "Visible",
          value: "2",
        },
      },
      {
        name: "Intermediate English Marks 2nd Year",
        value: "Marks2ndYear",
        isFilter: true,
        dataType: "number",
        selections: {},
        fieldType: 1,
        trigger: {
          id: 2,
          compareId: "Country",
          type: "Visible",
          value: "2",
        },
      },
    ],
  },
  {
    title: "Admission Details",
    description: "Requirements and Process for Admission",
    columnType: 2,
    details: [
      {
        name: "Which English test did you take? *",
        value: "IeltsOverall",
        isFilter: true,
        dataType: "Int",
        selections: { "1": "TOEFL", "2": "IELTS", "3": "PTE", "4": "DUOLINGO" },
        fieldType: 9,
        trigger: null,
      },
      {
        name: "Reading Score",
        value: "ReadingScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: null,
      },

      {
        name: "Writing Score",
        value: "WritingScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: null,
      },
      {
        name: "Listening Score",
        value: "ListeningScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: null,
      },
      {
        name: "Speaking Score",
        value: "SpeakingScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: null,
      },

      {
        name: "Overall Score",
        value: "OverallScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: null,
      },

      {
        name: "Which aptitude test did you take? *",
        value: "AptitudeTest",
        isFilter: true,
        dataType: "Int",
        selections: { "1": "GMAT", "2": "GRE", "3": "NONE" },
        fieldType: 9,
        trigger: null,
      },
      {
        name: "Gmat Score",
        value: "Gmatscore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: {
          id: 1,
          compareId: "AptitudeTest",
          type: "Visible",
          value: "1",
        },
      },
      {
        name: "Gre Score",
        value: "GreScore",
        isFilter: true,
        dataType: "Int",
        selections: {},
        fieldType: 6,
        trigger: {
          id: 1,
          compareId: "AptitudeTest",
          type: "Visible",
          value: "2",
        },
      },
    ],
  },
  {
    title: "Financial Information",
    description: "Details on Fees and Financial Requirements",
    columnType: 1,
    details: [
      {
        name: "Which Intake are you planning ",
        value: "Intake",
        isFilter: true,
        dataType: "Int",
        selections: {
          "1": "August 2024",
          "2": "September 2024",
          "3": "October 2024",
          "4": "November 2024",
          "5": "December 2024",
          "6": "January 2025",
          "7": "February 2025",
          "8": "March 2025",
          "9": "April 2025",
          "10": "May 2025",
          "11": "June 2025",
          "12": "July 2025",
        },
        fieldType: 8,
        trigger: null,
      },
      {
        name: "Tuition Fee (USD)",
        value: "TuitionFee",
        isFilter: true,
        dataType: "Int",
        selections: {
          "1": "0 - $1000 / year",
          "2": "$1000 - $10,000 / year",
          "3": "$10,000 - $20,000 / year",
          "4": "$20,000 - $30,000 / year",
          "5": "Over $30,000 / year",
        },
        fieldType: 8,
        trigger: null,
      },
      {
        name: "Number of backlogs in Bachelors",
        value: "backlog",
        isFilter: true,
        dataType: "Int",
        selections: generateRangeSelections(1, 100),
        fieldType: 1,
        trigger: null,
      },
      {
        name: "Work Experience ( If any *)",
        value: "WorkExp",
        isFilter: true,
        dataType: "string",
        selections: { "1": "One Year", "2": "Two Years", "3": "Four Years" },
        fieldType: 8,
        trigger: null,
      },
    ],
  },
];

const validationSchemas = [
  Yup.object({
    StudyLevelId: Yup.number().required("Required"),
    Country: Yup.string().required("Required"),
    Concentration: Yup.string().required("Required"),
  }),
  Yup.object({
    College: Yup.string().required("Required"),
    Course: Yup.string().required("Required"),
    Duration: Yup.string().required("Required"),
    Locations: Yup.string().required("Required"),
    Marks1stYear: Yup.string().required("Required"),
    Marks2ndYear: Yup.string().required("Required"),
  }),
  Yup.object({
    IeltsOverall: Yup.number().required("Required"),
    ReadingScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),

    WritingScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),

    ListeningScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),
    SpeakingScore: Yup.number()
      .required("Required")
      .test("is-valid-Score", function (values) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };
        const thresholds: { [keys: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };
        if (IeltsOverall !== undefined && typeof values === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (values > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }
        return true;
      }),

    OverallScore: Yup.number()
      .required("Required")
      .test("is-valid-score", function (value) {
        const { IeltsOverall } = this.parent as { IeltsOverall: string };

        const thresholds: { [key: string]: number } = {
          "1": 100,
          "2": 9,
          "3": 100,
          "4": 150,
        };

        if (IeltsOverall !== undefined && typeof value === "number") {
          const maxScore = thresholds[IeltsOverall];
          if (value > maxScore) {
            return this.createError({
              message: `Score exceeds the limit of ${maxScore}`,
            });
          }
        }

        return true;
      }),

    Gmatscore: Yup.number().required("Required"),
    GreScore: Yup.number().required("Required"),

    DETScore: Yup.number().required("Required"),
    CGPAOrPercentage: Yup.number().required("Required"),
  }),
  Yup.object({
    Backlog: Yup.number().required("Required"),
    ApplicationFee: Yup.number().required("Required"),
    TuitionFee: Yup.number().required("Required"),
    I20DepositFee: Yup.number().required("Required"),
  }),
];
interface CollegeFinderProps {
  onSearchResult: (result: any) => void;
}
const CollegeFinder: React.FC<CollegeFinderProps> = ({ onSearchResult }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const steps = stepData.map((step) => step.title);
  const classes = useStyles();
  const stepLabelClasses = useStepLabelStyles();
  const buttonStyles = useButtonstyle();
  const formik: any = useFormik({
    initialValues: {
      Backlog: 0,
      Name: "",
      StudyLevelId: "",
      Country: "",
      Location: "",
      Concentration: "",
      College: "",
      Course: "",
      Duration: "",
      Locations: "",
      Marks1stYear: "",
      Marks2ndYear: "",
      WorkExp: "",
      BachelorsProgram: "",
      IeltsOverall: "1",
      ToeflScore: 0,
      ActScore: 0,
      GreScore: 0,
      DETScore: 0,
      CGPAOrPercentage: 0,
      ApplicationFee: "",
      TuitionFee: "",
      I20DepositFee: "",
      AptitudeTest: "1",
      Gmatscore: 0,
      ReadingScore: 0,
      WritingScore: 0,
      ListeningScore: 0,
      SpeakingScore: 0,
      OverallScore: 0,
    },
    validationSchema: validationSchemas[activeStep],
    onSubmit: async (values: any) => {
      // console.log("Form values ==", values);
    },
  });

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setShowLoading(true);
      setTimeout(async () => {
        const response = await api.post(`search-details`, {
          body: {
            dataTableId: formik.values["Country"],
            search: formik.values,
          },
        });
        if (response.status) {
          onSearchResult(response.data);
          setShowLoading(false);
        }
      }, 5000);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleselectionvalue = (value: any, fieldName: string) => {
    formik.setFieldValue(fieldName, value);
  };

  const shouldFieldBeVisible = (trigger: TriggerType | null) => {
    if (!trigger) return true;
    return formik.values[trigger.compareId] === trigger.value;
  };

  if (showLoading) return <LoadingComponent />;

  return (
    <FormikProvider value={formik}>
      <div style={{ border: "1px solid #dedede" }}>
        <Grid
          className={classes.fieldContainer}
          container
          justifyContent="space-between"
        >
          <div className={classes.stepTitleContainer}>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                marginLeft: "2.187em",
                paddingTop: "0.563em",
                fontWeight: 400,
                color: "white",
                fontSize: "1.31em",
              }}
            >
              {steps[activeStep]}
            </Typography>
            <div style={{ display: "flex" }}>
              {steps.map((step, index) => (
                <div
                  key={step}
                  className={classes.stepIcon}
                  style={{
                    backgroundColor:
                      index === activeStep ? "#ffab40" : "transparent",
                  }}
                >
                  {index + 1}
                </div>
              ))}
            </div>{" "}
          </div>
        </Grid>

        {/* <Stepper activeStep={activeStep} className={classes.fieldContainer}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel classes={{ label: stepLabelClasses.label }}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper> */}
        <Form>
          <Paper className={classes.formContainer} variant="outlined">
            <Typography variant="h6" gutterBottom>
              {stepData[activeStep].description}
            </Typography>

            <Grid container spacing={3}>
              {stepData[activeStep].details
                .filter((detail) => shouldFieldBeVisible(detail.trigger))
                .map((detail) => (
                  <Grid
                    item
                    xs={12}
                    sm={
                      detail.fieldType === 6 &&
                      shouldFieldBeVisible(detail.trigger)
                        ? 6
                        : stepData[activeStep].columnType === 2
                        ? 12
                        : 6
                    }
                    key={detail.value}
                  >
                    {detail.fieldType === 1 && !detail.trigger && (
                      <TextField
                        id={detail.value}
                        name={detail.value}
                        label={detail.name}
                        fullWidth
                        value={formik.values[detail.value]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[detail.value] &&
                          Boolean(formik.errors[detail.value])
                        }
                        helperText={
                          formik.touched[detail.value] &&
                          formik.errors[detail.value]
                        }
                      />
                    )}
                    {detail.fieldType === 8 && !detail.trigger && (
                      <FormControl fullWidth>
                        <InputLabel>{detail.name}</InputLabel>
                        <Select
                          id={detail.value}
                          name={detail.value}
                          value={formik.values[detail.value]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[detail.value] &&
                            Boolean(formik.errors[detail.value])
                          }
                        >
                          {Object.entries(detail.selections).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                    {detail.fieldType === 9 && (
                      <FormControl fullWidth>
                        <InputLabel>{detail.name}</InputLabel>
                        <RadioButtonStyle>
                          <SingleSelectionComponent
                            options={detail.selections as any}
                            onChange={(value) => {
                              handleselectionvalue(value, detail.value);
                            }}
                          />
                        </RadioButtonStyle>
                      </FormControl>
                    )}

                    {detail.fieldType === 6 &&
                      shouldFieldBeVisible(detail.trigger) && (
                        <TextField
                          id={detail.value}
                          name={detail.value}
                          label={detail.name}
                          type="number"
                          fullWidth
                          value={formik.values[detail.value]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[detail.value] &&
                            Boolean(formik.errors[detail.value])
                          }
                          helperText={
                            formik.touched[detail.value] &&
                            formik.errors[detail.value]
                          }
                        />
                      )}
                  </Grid>
                ))}
            </Grid>
          </Paper>
          <div className={classes.buttonContainer}>
            {activeStep !== steps.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                className={buttonStyles.button}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleNext}
                className={buttonStyles.button}
              >
                Submit
              </Button>
            )}

            {activeStep !== 0 && (
              <Button
                className={buttonStyles.button}
                type="button"
                variant="contained"
                onClick={handleBack}
                style={{ marginRight: "8px" }}
              >
                Back
              </Button>
            )}
          </div>
        </Form>
      </div>
    </FormikProvider>
  );
};

export default CollegeFinder;
